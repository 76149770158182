import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { httpOptions } from '@dash/randy/dashboard/services/db-crud.service';
import { HttpClient } from '@angular/common/http';
import { LogService } from '@dash/randy/shared/classes/log.service';
import { UserApplicationModel } from '../models/user.model';
import { CognitoService } from '@dash/randy/security/service/cognito.service';
import { CUserModel } from '@dash/randy/security/models/cUser.model';
import { FilterQueryModel } from '../../advanced-search/classes/filter.model';
import * as Sentry from '@sentry/angular';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const FIREBASE_TOKEN = 'auth-firebase';
const SELECTED_APPLICATION = 'auth-application';
const NEXT_OBJECT_SWITCHER = 'next-object-switcher';

@Injectable({
  providedIn: 'root',
})
export class TokenStorageService {
  private synced: boolean;

  constructor(
    protected http: HttpClient,
    private logService: LogService,
    @Inject(LOCALE_ID) protected localeId: string,
  ) {}

  public saveFirebaseToken(token: string): void {
    window.localStorage.removeItem(FIREBASE_TOKEN);
    window.localStorage.setItem(FIREBASE_TOKEN, token);
  }

  public getFirebaseToken(): string | null {
    return window.localStorage.getItem(FIREBASE_TOKEN);
  }

  public getLocale(): string {
    return this.localeId;
  }

  getExtraUserData(): Promise<UserApplicationModel[]> {
    return new Promise<UserApplicationModel[]>((resolve, reject) => {
      this.http.get<UserApplicationModel[]>('https://api.kleksi.com/admin/applications', httpOptions).subscribe(
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        },
      );
    });
  }

  getSelectedApplication(): UserApplicationModel {
    let data = null;
    let strData = null;

    try {
      strData = window.localStorage.getItem(SELECTED_APPLICATION);
      if (!strData) {
        return null;
      }
      data = JSON.parse(strData);
      if (!data) {
        return null;
      }
    } catch (e) {
      console.error('Error with selecting an application', e);
      Sentry.captureException(e, {
        extra: {
          strData: strData,
          message: 'Error with selecting an application',
        },
      });
    }
    return data;
  }

  setSelectedApplication(application: UserApplicationModel): void {
    window.localStorage.removeItem(SELECTED_APPLICATION);
    window.localStorage.setItem(SELECTED_APPLICATION, JSON.stringify(application));
  }

  getNextObjectSwitcher(): { searchkey: string; index: number; query: FilterQueryModel } {
    let strData = window.sessionStorage.getItem(NEXT_OBJECT_SWITCHER);
    if (!strData) {
      return null;
    }
    let data = JSON.parse(strData);
    if (!data) {
      return null;
    }
    return data;
  }

  getNextObjectSwitcherSearchkey(): string {
    let strData = window.sessionStorage.getItem(NEXT_OBJECT_SWITCHER);
    if (!strData) {
      return null;
    }
    let data = JSON.parse(strData);
    if (!data) {
      return null;
    }
    return data.searchkey;
  }

  getNextObjectSwitcherIndex(): number {
    let strData = window.sessionStorage.getItem(NEXT_OBJECT_SWITCHER);
    if (!strData) {
      return 0;
    }
    let data = JSON.parse(strData);
    if (!data) {
      return 0;
    }
    return data.index;
  }

  getNextObjectSwitcherQuery(): FilterQueryModel {
    let strData = window.sessionStorage.getItem(NEXT_OBJECT_SWITCHER);
    if (!strData) {
      return null;
    }
    let data = JSON.parse(strData);
    if (!data) {
      return null;
    }
    return data.query;
  }

  setNextObjectSwitcherSearchkey(searchkey: string): void {
    let strData = window.sessionStorage.getItem(NEXT_OBJECT_SWITCHER);
    let data;
    if (!strData) {
      data = { searchkey: searchkey };
    } else {
      data = JSON.parse(strData);
      data.searchkey = searchkey;
    }
    window.sessionStorage.setItem(NEXT_OBJECT_SWITCHER, JSON.stringify(data));
  }

  setNextObjectSwitcherIndex(index: number): void {
    let strData = window.sessionStorage.getItem(NEXT_OBJECT_SWITCHER);
    let data;
    if (!strData) {
      data = { index: index };
    } else {
      data = JSON.parse(strData);
      data.index = index;
    }
    window.sessionStorage.setItem(NEXT_OBJECT_SWITCHER, JSON.stringify(data));
  }

  setNextObjectSwitcherQuery(query: FilterQueryModel): void {
    let strData = window.sessionStorage.getItem(NEXT_OBJECT_SWITCHER);
    let data;
    if (!strData) {
      data = { query: query };
    } else {
      data = JSON.parse(strData);
      data.query = query;
    }
    window.sessionStorage.setItem(NEXT_OBJECT_SWITCHER, JSON.stringify(data));
  }

  getLimitsForCurrentApplication() {
    let app = this.getSelectedApplication();
    return app.license;
  }

  // getPermissions(permission: string): boolean {
  //   if (this.isSuperAdmin()) {
  //     return true;
  //   }
  //   let decodedToken = this.getSelectedApplication();
  //   let index = decodedToken.permissions.findIndex(row => row === permission);
  //   return index !== -1;
  // }
}
