import { Injectable } from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import * as Sentry from '@sentry/angular';
import {filter, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SentryTransactionService {
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Get the active route
        let activeRoute = this.activatedRoute.root;
        while (activeRoute.firstChild) {
          activeRoute = activeRoute.firstChild;
        }

        console.log('before activeRoute', activeRoute);
        console.log('before event', event)

        let url = event.urlAfterRedirects;
        for (const key of Object.keys(activeRoute.snapshot.params)) {
          url = url.replace(activeRoute.snapshot.params[key], '<' + key + '>');
        }
        console.log('Before url', url);
        // Get the transaction name
        const transactionName = url;
        Sentry.getCurrentHub().configureScope(scope => {
          scope.setTransactionName(transactionName);
          console.log('Before route params', activeRoute.snapshot.params);
          scope.setExtras(activeRoute.snapshot.params);
        });
      }
    });
  }

}
