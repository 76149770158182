import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { BehaviorSubject, firstValueFrom, lastValueFrom, Observable, Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { CUserModel, RawCognitoUser } from '@dash/randy/security/models/cUser.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApplicationsModel } from '../../admin-panel/applications/classes/applications.model';
import { TokenStorageService } from '@dash/randy/security/service/token-storage.service';
import * as Sentry from '@sentry/angular';
import { OAuthErrorEvent } from 'angular-oauth2-oidc/events';
import { DebugService } from '@dash/randy/shared/classes/debug.service';
import {MatLegacySnackBar as MatSnackBar} from "@angular/material/legacy-snack-bar";

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private authenticationSubject: BehaviorSubject<any>;
  private user: CUserModel = null;
  private applications: { name: string; id: string }[];
  private application: { name: string; id?: string };
  private permissions: { [id: string]: boolean } = {};

  private static oauthSubscription: Subscription = null;

  constructor(
    private oauthService: OAuthService,
    private tokenStorage: TokenStorageService,
    private http: HttpClient,
    private router: Router,
    private snackbar: MatSnackBar,
    private debugService: DebugService,
    @Inject(LOCALE_ID) protected localeId: string,
  ) {
    this.authenticationSubject = new BehaviorSubject<boolean>(false);
    this.oauthService.setStorage(localStorage);

    let cognitoConfig = environment.aws.cognito;
    cognitoConfig.redirectUri = cognitoConfig.redirectUri.replace('%LOCALE%', this.localeId);
    cognitoConfig.logoutUrl = cognitoConfig.logoutUrl.replace('%LOCALE%', this.localeId);
    this.oauthService.configure(cognitoConfig);

    if (CognitoService.oauthSubscription && !CognitoService.oauthSubscription.closed) {
      CognitoService.oauthSubscription.unsubscribe();
    }
    CognitoService.oauthSubscription = this.oauthService.events.subscribe((event: OAuthEvent) => {
      console.log('oauthService event', event, new Date());

      switch (event.type) {
        case 'token_refresh_error':
          let errorEvent = event as OAuthErrorEvent;
          console.log('token_refresh_error', event);
          Sentry.captureMessage(event.type, {
            extra: {
              reason: errorEvent?.reason,
              params: errorEvent?.params,
            },
          });
          this.signOut().then(() => {
            this.router.navigate(['login']).then(() => {
              window.location.reload();
              this.snackbar.open(
                $localize`:@@error-refreshing-token:Er is een fout opgetreden bij het vernieuwen van de token.`,
                $localize`:@@close:Sluiten`,
                {
                  duration: 5000,
                },
              );
            });
          });
      }
    });
  }

  getAuthEvents(): Observable<OAuthEvent> {
    return this.oauthService.events;
  }

  async init(): Promise<boolean> {
    console.log('CognitoService init');
    let discovery = await this.oauthService.loadDiscoveryDocument().catch(err => {
      console.error(err, 'Couldnt load discovery document');
      return false;
    });

    if (!discovery) {
      console.log('discovery', discovery);

      this.signOut().then(() => {
        this.router.navigate(['login']).then(() => {
          window.location.reload();
        });
      });
      return false;
    }

    this.oauthService.logoutUrl = environment.aws.cognito.logoutUrl.replace('%LOCALE%', this.localeId);

    let loggedIn = await this.oauthService.tryLogin().catch(err => {
      console.error(err, 'Couldnt "try" login');
      return false;
    });

    if (!loggedIn) {
      console.log('loggedIn', loggedIn);
      this.signOut().then(() => {
        this.router.navigate(['login']).then(() => {
          window.location.reload();
        });
      });
      return false;
    }

    this.oauthService.revocationEndpoint = this.oauthService.userinfoEndpoint.replace('userInfo', 'revoke');
    // const loginResult = await this.oauthService.tryLogin();
    // console.log('loginResult', loginResult);
    if (!this.isAuthenticated()) {
      console.warn('User isnt authenticated');
      let refreshTokenExists = this.oauthService.getRefreshToken() !== null;

      if (!refreshTokenExists) {
        console.warn('Refresh token doesnt exist');
        console.log(this.router.url, 'router url');
        // if (this.router.url !== '/login') {
        this.router.navigate(['login']).then(() => {
          // window.location.reload();
        });
        return false;
        // }
      }

      let refreshTokenResponse = await this.oauthService
        .refreshToken()
        .then(response => {
          console.log('refreshTokenResponse', response);
          return response;
        })
        .catch(err => {
          console.error(err, 'Couldnt refresh token');
          return false;
        });
      if (!refreshTokenResponse) {
        this.oauthService.logOut(false);
        this.router.navigate(['login']).then(() => {
          window.location.reload();
        });
        return false;
      } else {
        console.log('refreshTokenIsValid', refreshTokenResponse);
      }
    }
    console.log('isAuthenticated');

    const timeLeft = this.oauthService.getAccessTokenExpiration();
    console.log('timeLeft', new Date(timeLeft));

    if (timeLeft < Date.now() + 1000 * 60 * 5) {
      console.warn('Access token is expired');
      await this.oauthService.refreshToken().catch(err => {
        console.error(err, 'Couldnt refresh the refresh token');
        this.signOut();
        this.router.navigate(['login']);
      });
    }

    this.oauthService.setupAutomaticSilentRefresh();

    console.log('Start getting user');
    this.user = await this.getUser().catch(err => {
      console.error(err, 'Couldnt get the user info');
      return null;
    });
    console.log('user', this.user);
    if (!this.user) {
      console.warn('Cognito init failed');
      this.signOut().then(() => {
        this.router.navigate(['login']).then(() => {
          window.location.reload();
        });
      });
      return false;
    }
    const extraUserData = await this.getExtraUserData().catch(err => {
      console.error(err, 'Couldnt get extra user data');
      return null;
    });

    if (extraUserData) {
      this.user.isSuperadmin = extraUserData.superadmin;
      this.applications = extraUserData.applications;

      const currentApplication = this.tokenStorage.getSelectedApplication();
      if (!currentApplication) {
        this.tokenStorage.setSelectedApplication(this.applications[0]);
      }else{
        this.tokenStorage.setSelectedApplication(this.applications.find(row => row.id === currentApplication.id));
      }

      Sentry.setUser({
        username: this.user.username,
        email: this.user.email,
        applicationName: this.tokenStorage.getSelectedApplication().name,
      });
      Sentry.setContext('application', {
        name: this.tokenStorage.getSelectedApplication().name,
        id: this.tokenStorage.getSelectedApplication().id,
        version: environment.appVersion ?? 'No version',
        localeId: this.localeId,
        localizeLocale: $localize.locale ?? 'No locale',
      });
      Sentry.setTag('locale', this.localeId);
      Sentry.setTag('applicationId', this.tokenStorage.getSelectedApplication().id);
    } else {
      console.error('This shouldnt happen...');
    }
    return true;
  }

  public signIn(): Promise<boolean> {
    return this.oauthService.loadDiscoveryDocumentAndLogin().catch(err => {
      console.error(err, 'Logging in isnt possible');
      return false;
    });
  }

  public async signOut(): Promise<void> {
    await this.revokeRefreshToken().catch(err => {
      console.error(err, 'Couldnt revoke the refresh token');
    });
    this.oauthService.logOut();
  }

  public isAuthenticated(): boolean {
    return this.getAccessToken() !== null && this.oauthService.hasValidAccessToken();
  }

  protected async revokeRefreshToken(): Promise<void> {
    let refreshToken = this.oauthService.getRefreshToken();
    if (!refreshToken) {
      return;
    }

    let params = new HttpParams();

    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    params = params.set('client_id', this.oauthService.clientId);

    let revokationParams = params.set('token', refreshToken).set('token_type_hint', 'refresh_token');
    return lastValueFrom(this.http.post<void>(this.oauthService.revocationEndpoint, revokationParams, { headers }));
  }

  public getUsername() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }

    return claims;
  }

  public async getUser(): Promise<CUserModel> {
    if (this.user) {
      return this.user;
    }
    const rawUser = await this.getRawUser();
    if (!rawUser) {
      return null;
    }
    if (rawUser['cognito:username'] !== rawUser.username) {
      console.warn('Username doesnt match with cognito username');
    }

    const user = {
      application: null,
      email: rawUser.email,
      emailVerified: rawUser.email_verified === 'true',
      tokenUse: rawUser.token_use,
      username: rawUser.username,
      isSuperadmin: false,
    };
    this.user = user;
    return user;
  }

  public async getRawUser(): Promise<RawCognitoUser> {
    if (!this.isAuthenticated()) {
      return null;
    }
    const userProfile = await this.oauthService.loadUserProfile().catch(err => {
      console.error(err, 'Couldnt get the user profile');
      return null;
    });
    if (!userProfile) {
      return null;
    }
    return userProfile['info'] as RawCognitoUser;
  }

  async handleCallBack(code: string = null) {
    const result = await this.oauthService.loadDiscoveryDocumentAndTryLogin();

    await this.setLocalUser();
  }

  async setLocalUser() {
    this.authenticationSubject.next(true);
  }

  isSuperAdmin() {
    if (!this.user) {
      return false;
    }
    return this.user.isSuperadmin;
    // if (!token) {
    //   return false;
    // }
    // let decodedToken = JSON.parse(window.atob(token.split('.')[1]));
    // return decodedToken.role && decodedToken.role === 'superadmin';
  }

  getAccessToken(): string | null {
    return this.oauthService.getAccessToken();
  }

  getAccessTokenExpiration(): Date {
    return new Date(this.oauthService.getAccessTokenExpiration());
  }

  async getExtraUserData(): Promise<{ superadmin: boolean; applications: ApplicationsModel[] }> {
    const result = await firstValueFrom(
      this.http.get<{
        superadmin: boolean;
        applications: ApplicationsModel[];
      }>(environment.aws.allowedApiUrls[0] + '/user/info'),
    );
    console.log('extraUserData', result);
    if (this.tokenStorage.getSelectedApplication() === null) {
      return result;
    }
    let app = result.applications.find(row => row.id === this.tokenStorage.getSelectedApplication().id);
    if (!app) {
      return result;
    }
    this.permissions = app.permissions ?? {};
    if (!this.permissions || JSON.stringify(this.permissions) === '{}') {
      console.error('No permissions found');
    }
    return result;
  }

  getApplications(): { name: string; id: string }[] {
    return this.applications.sort((a, b) => a.name.localeCompare(b.name));
  }

  async selectApplication(app: { name: string; id: string }) {
    this.application = app;
    this.tokenStorage.setSelectedApplication(app);
  }

  getPermissions(permission: string): boolean {
    if (this.isSuperAdmin()) {
      this.savePermissionToLocalStorage(permission);
      return true;
    }
    return this.hasPermission(permission);
  }

  hasPermission(permission: string): boolean {
    this.savePermissionToLocalStorage(permission);
    const value = this.permissions[permission];
    if (value !== undefined) {
      return value;
    }

    if (permission !== '*') {
      const dotIndex = permission.lastIndexOf('.');
      if (dotIndex >= 0) {
        return this.hasPermission(permission.substr(0, dotIndex));
      } else {
        return this.hasPermission('*');
      }
    }
    return false;
  }

  savePermissionToLocalStorage(permission: string) {
    if (!this.debugService.isDebugSync()) {
      return;
    }

    let permissions: Set<string> = new Set(JSON.parse(localStorage.getItem('permissions') ?? '[]'));
    permissions.add(permission);
    localStorage.setItem('permissions', JSON.stringify(Array.from(permissions)));
  }

  async validateAuth() {
    console.log('validateAuth');
    if (this.isAuthenticated()) {
      console.log('isAuthenticated');
      const result = await this.oauthService.refreshToken().catch(err => {
        console.error(err);
        this.signOut().then(() => {
          this.router.navigate(['login']).then(() => window.location.reload());
        });
      });
      console.log('Refresh token result', result);
    } else {
      console.log('not isAuthenticated');
    }
  }
}
