import { Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { LayoutModule } from '@angular/cdk/layout';
import { QuillModule } from 'ngx-quill';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuillComponent } from './components/quill/quill.component';
import { HttpClientModule } from '@angular/common/http';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ConfirmDialogComponent } from '@dash/randy/shared/components/confirm-dialog/confirm-dialog.component';
import { StarRatingComponent } from '@dash/randy/shared/components/star-rating/star-rating.component';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatTreeModule } from '@angular/material/tree';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SmallStatusComponent } from './components/small-status/small-status.component';
import { FileManagerItemIconComponent } from './components/file-manager-item-icon/file-manager-item-icon.component';
import { ImgFileEditorComponent } from './components/img-file-editor/img-file-editor.component';
import { ImgCdnLinkPipe } from './components/file-manager-item-icon/img-cdn-link.pipe';
import { MatTableHeaderToolbarComponent } from './components/mat-table-header-toolbar/mat-table-header-toolbar.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { DragAndDropDirective } from './directive/drag-and-drop.directive';
import { SafePipe } from './pipes/safe.pipe';
import { MatBadgeModule } from '@angular/material/badge';
import { JoyrideModule } from 'ngx-joyride';
import { BaseComponent } from '@dash/randy/shared/classes/baseComponent';
import { DraggableDirective } from './directive/draggable.directive';
import { GlobalPageHeaderComponent } from './components/global-page-header/global-page-header.component';
import { VideoDialogComponent } from './components/video-dialog/video-dialog.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { NgModelChangeDebouncedDirective } from './directive/ng-model-change-debounced.directive';
import { HideProdDirective } from './directive/hide-prod.directive';
import { ConfirmDialogDirective } from './directive/confirm-dialog.directive';
import { ButtonSpinnerComponent } from './components/button-spinner/button-spinner.component';
import { DragAndDropOverlayComponent } from './components/drag-and-drop-overlay/drag-and-drop-overlay.component';
import { StorageSizePipe } from './pipes/storage-size.pipe';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { AdminUserEmailPipe } from '@dash/randy/shared/pipes/admin-user-email.pipe';

import { SimpleMatCardComponent } from './components/simple-mat-card/simple-mat-card.component';
import { CollectionItemFieldPipe } from './pipes/collection-item-field.pipe';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { WithLoadingPipe } from '@dash/randy/shared/pipes/with-loading.pipe';
import { ImgCropperComponent } from './components/img-cropper/img-cropper.component';
import { IfDebugDirective } from './directive/if-debug.directive';
import { MtxTooltipModule } from '@ng-matero/extensions/tooltip';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { AuthPermissionCheckDirective } from './directive/auth-permission-check.directive';
import { AuthPermissionCheckInputDirective } from '@dash/randy/shared/directive/auth-permission-check-input.directive';
import { ApplicationFromIdPipe } from './pipes/application-from-id.pipe';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { AppInjector } from '@dash/randy/shared/service/app.injector';
import { MatStepperModule } from '@angular/material/stepper';
import { CollectionItemDataDisplayPipe } from './pipes/collection-item-data-display.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { BasePageComponent } from '@dash/randy/shared/components/base-page/base-page.component';
import { RouterModule } from '@angular/router';
import { SelectObjectTypeComponent } from './components/select-object-type/select-object-type.component';
import { LocaleSimplePipe } from '@dash/randy/shared/pipes/locale-simple.pipe';
import { MouseOverDirective } from '@dash/randy/shared/directive/mouse-over.directive';
import { AddHistoryRecordDirective } from './directive/add-history-record.directive';
import { GoToHistoryRecordDirective } from '@dash/randy/shared/directive/go-to-history-record.directive';
import { HideOnMobileDirective } from '@dash/randy/shared/directive/hide-on-mobile.directive';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { VideoIDDirective } from './directive/video-id.directive';
import { ResizableColDirective } from '@dash/randy/shared/directive/resizable-col.directive';
import { ResizableColComponent } from '@dash/randy/shared/components/resizable-col/resizable-col.component';
import { NumbersOnlyDirective } from './directive/numbers-only.directive';
import { TruncatePipe } from '@dash/randy/shared/pipes/truncate.pipe';
import { AutofocusDirective } from '@dash/randy/shared/directive/autofocus.directive';
import { IfDemoDirective } from '@dash/randy/shared/directive/if-demo.directive';
import { IfNotDemoDirective } from '@dash/randy/shared/directive/if-not-demo.directive';
import { CustomSnackbarComponent } from '@dash/randy/shared/components/snackbar-components/custom-snackbar/custom-snackbar.component';
import { LoadingSnackbarComponent } from '@dash/randy/shared/components/snackbar-components/loading-snackbar/loading-snackbar.component';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { LoadingImageComponent } from '@dash/randy/shared/components/loading-img/loading-image.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BytesPipe } from '@dash/randy/shared/pipes/bytes.pipe';
import { VideoCdnLinkPipe } from '@dash/randy/shared/components/file-manager-item-icon/video-cdn-link.pipe';
import { AudioCdnLinkPipe } from '@dash/randy/shared/components/file-manager-item-icon/audio-cdn-link.pipe';
import { VideoViewerComponent } from './components/video-viewer/video-viewer.component';
import {MAT_TOOLTIP_DEFAULT_OPTIONS} from "@angular/material/tooltip";

@NgModule({
  declarations: [
    QuillComponent,
    ConfirmDialogComponent,
    StarRatingComponent,
    SpinnerComponent,
    SmallStatusComponent,
    BaseComponent,
    FileManagerItemIconComponent,
    ImgFileEditorComponent,
    ImgCdnLinkPipe,
    MatTableHeaderToolbarComponent,
    DragAndDropDirective,
    SafePipe,
    DraggableDirective,
    GlobalPageHeaderComponent,
    VideoDialogComponent,
    DateAgoPipe,
    NgModelChangeDebouncedDirective,
    HideProdDirective,
    ConfirmDialogDirective,
    ButtonSpinnerComponent,
    DragAndDropOverlayComponent,
    StorageSizePipe,
    AdminUserEmailPipe,
    ApplicationFromIdPipe,
    SimpleMatCardComponent,
    LocaleSimplePipe,
    TruncatePipe,
    BytesPipe,

    AuthPermissionCheckDirective,
    AuthPermissionCheckInputDirective,
    CollectionItemFieldPipe,
    WithLoadingPipe,
    ImgCropperComponent,
    MouseOverDirective,
    IfDebugDirective,
    IfDemoDirective,
    IfNotDemoDirective,
    CollectionItemDataDisplayPipe,
    ErrorPageComponent,
    BasePageComponent,
    SelectObjectTypeComponent,
    AddHistoryRecordDirective,
    GoToHistoryRecordDirective,
    HideOnMobileDirective,
    VideoIDDirective,
    ResizableColComponent,
    ResizableColDirective,
    NumbersOnlyDirective,
    AutofocusDirective,
    CustomSnackbarComponent,
    LoadingSnackbarComponent,
    LoadingImageComponent,
    BackButtonComponent,
    VideoCdnLinkPipe,
    AudioCdnLinkPipe,
    VideoViewerComponent,
  ],
  imports: [
    CommonModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatBottomSheetModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    LayoutModule,
    QuillModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatTreeModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatExpansionModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FlexLayoutModule,
    DragDropModule,
    MatSliderModule,
    MatSlideToggleModule,
    NgxDropzoneModule,
    NgxSpinnerModule,
    MatBadgeModule,
    JoyrideModule.forChild(),
    MatTabsModule,
    RouterModule,
    NgxMatSelectSearchModule,
  ],
  exports: [
    CommonModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatCheckboxModule,
    MatSelectModule,
    MatRadioModule,
    MatCardModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    LayoutModule,
    QuillModule,
    QuillComponent,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatChipsModule,
    MatExpansionModule,
    FlexLayoutModule,
    DragDropModule,
    MatSliderModule,
    MatSlideToggleModule,
    NgxMatSelectSearchModule,
    NgxSpinnerModule,
    JoyrideModule,
    MatStepperModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ConfirmDialogComponent,
    StarRatingComponent,
    NgxDropzoneModule,
    SpinnerComponent,
    SmallStatusComponent,
    BaseComponent,
    FileManagerItemIconComponent,
    MatTableHeaderToolbarComponent,
    DragAndDropDirective,
    ImgCdnLinkPipe,
    SafePipe,
    DraggableDirective,
    ResizableColComponent,
    ResizableColDirective,
    GlobalPageHeaderComponent,
    DateAgoPipe,
    NgModelChangeDebouncedDirective,
    HideProdDirective,
    ConfirmDialogDirective,
    ButtonSpinnerComponent,
    DragAndDropOverlayComponent,
    StorageSizePipe,
    AdminUserEmailPipe,
    ApplicationFromIdPipe,
    SimpleMatCardComponent,
    CollectionItemFieldPipe,
    MatTabsModule,
    MatButtonToggleModule,
    WithLoadingPipe,
    IfDebugDirective,
    IfDemoDirective,
    AuthPermissionCheckDirective,
    AuthPermissionCheckInputDirective,
    MouseOverDirective,
    MtxTooltipModule,
    CollectionItemDataDisplayPipe,
    ImgCdnLinkPipe,
    ErrorPageComponent,
    BasePageComponent,
    SelectObjectTypeComponent,
    AddHistoryRecordDirective,
    HideOnMobileDirective,
    VideoIDDirective,
    NumbersOnlyDirective,
    TruncatePipe,
    AutofocusDirective,
    IfNotDemoDirective,
    LoadingImageComponent,
    BackButtonComponent,
    BytesPipe,
    VideoCdnLinkPipe,
    AudioCdnLinkPipe,
  ],
  providers: [ImgCdnLinkPipe, MatDatepickerModule, BytesPipe,
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        disableTooltipInteractivity: true, // this enables pass through for tooltips
      },
    },
  ],
})
export class SharedModule {
  constructor(injector: Injector, private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    AppInjector.setInjector(injector);
    //http://localhost:4200/assets/custom_material_icons/columns_edit.svg

    console.log('Registering custom icons');
    try {
      matIconRegistry.addSvgIcon(
        'columns_edit',
        this.domSanitizer.bypassSecurityTrustResourceUrl('assets/columns_edit.svg'),
      );
    } catch (e) {
      console.error(e, 'Couldnt load columns svg icon');
    }
  }
}
