import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { lastValueFrom } from 'rxjs';
import { RoleModel } from '../models/role.model';
import { CognitoService } from './cognito.service';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private urlBase: string;
  private roles: RoleModel[];

  constructor(private http: HttpClient, private cognitoService: CognitoService) {
    this.urlBase = environment.publicAdminApiUrl;
  }

  async init() {
    if (this.cognitoService.isAuthenticated()) {
      this.loadRoles().then(roles => {
        this.roles = roles;
      });
    }
    return true;
  }

  getRoles(): RoleModel[] {
    return Object.assign([], this.roles);
  }

  async loadRoles(): Promise<RoleModel[]> {
    return lastValueFrom(this.http.get<RoleModel[]>(this.urlBase + 'roles')).catch(error => {
      console.log(error);
      return [];
    });
  }

  convertStringsToModels(ids: string[]): RoleModel[] {
    if (!this.roles) {
      return [];
    }
    return ids.map(row => {
      return this.roles.find(role => role.id === row);
    });
  }
}
