import { HttpClient } from '@angular/common/http';
import {Inject, Injectable, LOCALE_ID} from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { ChatGPTAPI, ChatMessage } from 'chatgpt';
import { CollectionItemModel } from '../../../../modules/crud/collections/collection-item/classes/collection-item.model';
import { LocaleUtilService } from '@dash/randy/shared/classes/locale-util.service';
import * as FieldRootReducer from '../../../../modules/crud/fields/field-root/store/reducers/field-root.reducer';
import { select, Store } from '@ngrx/store';
import * as FieldValueReducer from '../../../../modules/crud/fields/field-value/store/reducers/field-value.reducer';
import { CollectionModel } from '../../../../modules/crud/collections/collection/classes/collection.model';
import * as CollectionReducer from '../../../../modules/crud/collections/collection/store/reducers/collection.reducer';
import { BehaviorSubject, firstValueFrom, tap, map, take } from 'rxjs';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthInterceptor } from '@dash/randy/security/helpers/auth.interceptor';
import { AuthorizationService } from '@dash/randy/security/service/authorization.service';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class ChatGPTService {
  get apiUrl(): string {
    return this._apiUrl;
  }

  set apiUrl(value: string) {
    if (value.startsWith('http://') || value.startsWith('https://')) {
      this._apiUrl = value;
      return;
    }
    this._apiUrl = this.authorizationService.getApiUrl(false, true) + value;
  }

  private _apiUrl: string;

  set tokensLeft(value: number) {
    this._tokensLeft = value;
    this.tokensLeft$.next(value);
  }

  get tokensLeft(): number {
    return this._tokensLeft;
  }

  private _tokensLeft = 5000;
  public tokensLeft$ = new BehaviorSubject<number>(this._tokensLeft);

  constructor(
    private http: HttpClient,
    private localeService: LocaleUtilService,
    private fieldRootStore: Store<FieldRootReducer.FieldRootsState>,
    private fieldValueStore: Store<FieldValueReducer.FieldValuesState>,
    private collectionStore: Store<CollectionReducer.CollectionsState>,
    private matSnackBar: MatSnackBar,
    private authorizationService: AuthorizationService,
    @Inject(LOCALE_ID) protected locale: string
  ) {
    this._apiUrl = authorizationService.getApiUrl(false, true) + 'gpt';
    let tokensLeft = sessionStorage.getItem('tokensLeft');
    let tokensLeftNumber = Number(tokensLeft);
    console.log('Tokens left raw', tokensLeft);
    console.log('Tokens left number', tokensLeftNumber);
    if (!tokensLeft || isNaN(tokensLeftNumber) || tokensLeftNumber < 0) {
      tokensLeftNumber = 5000;
      console.log('Token was invalid, resetting to 5000');
    }

    console.log('Tokens left paresed', tokensLeftNumber);
    this.tokensLeft = tokensLeftNumber;

    sessionStorage.setItem('tokensLeft', this.tokensLeft.toString());
  }

  async getRawResponse(
    prompt: string,
    conversationId: string = null,
    parentMessageId: string = null,
  ): Promise<ChatGPTResponse> {
    const transaction = Sentry.startTransaction({
      name: 'chatbot-request',
      op: 'http.request',
      sampled: true,
      data: {
        prompt: prompt,
        conversationId: conversationId,
        parentMessageId: parentMessageId,
      },
    });
    console.log('Sending prompt', prompt);
    AuthInterceptor.disableErrorRedirection = true;

    const body = {
      text: prompt,
      parentMessageId: parentMessageId,
      conversationId: conversationId,
      locale: this.locale
    };


    return firstValueFrom(
      this.http
        .post<ChatGPTResponse>(this._apiUrl, body, {
          headers: {
            'sentry-trace': transaction.toTraceparent(),
          },
        })
        .pipe(
          take(1),
          tap((result: ChatGPTResponse) => {
            this.handleTokens(result);
          }),
          map((result: ChatGPTResponse) => {
            if (!result.id) {
              result.id = result['parentMessageId'];
            }
            return result;
          }),
          tap(result => {
            console.log('ChatGPT result', result);
            transaction.setData('result', result);
          }),
        ),
    ).finally(() => {
      transaction.finish();
      AuthInterceptor.disableErrorRedirection = false;
    });
  }

  async demoPrompt(
    prompt: string,
    conversationId: string = null,
    parentMessageId: string = null,
  ): Promise<ChatGPTResponse> {
    return this.getRawResponse(prompt, conversationId, parentMessageId);
  }

  async sendPrompt(collectionItem: CollectionItemModel, prompt: string): Promise<string> {
    console.log('Sending prompt', prompt);
    let relevantInfo = await this.extractInfoFromCollectionItem(collectionItem);
    console.log('Relevant info', relevantInfo);

    let question = JSON.stringify(relevantInfo) + '\n\n' + prompt;
    return this.getRawResponse(question)
      .then(result => {
        return result.text;
      })
      .catch(error => {
        console.error('Error sending prompt', error);
        this.matSnackBar.open(
          $localize`:@@errorSendingPrompt:Fout bij het versturen van de prompt: ${error?.error?.message}`,
          $localize`:@@close:Sluiten`,
          {
            duration: 5000,
          },
        );
        return null;
      });
  }

  private handleTokens(result: ChatGPTResponse) {
    console.log('Tokens left', this._tokensLeft);
    if (!result.tokens) {
      return;
    }
    let tokensUsed = result.tokens.prompt + result.tokens.completion;
    console.log('Tokens used', tokensUsed);

    this.tokensLeft = this._tokensLeft - tokensUsed;
    console.log('Tokens left after', this._tokensLeft);
    sessionStorage.setItem('tokensLeft', this._tokensLeft.toString());

    this.matSnackBar.open($localize`:@@tokensUsed:Tokens gebruikt: ${tokensUsed}`, $localize`:@@close:Sluiten`, {
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    });
  }

  async extractInfoFromCollectionItem(collectionItem: CollectionItemModel): Promise<{}> {
    let relevantInfo = {};
    relevantInfo['name'] = this.localeService.getFromLocales(collectionItem.name, 'nl').data;
    relevantInfo['description'] = collectionItem.description;

    relevantInfo['collections'] = [];
    for (const collectionId of collectionItem.collectionIds) {
      const collection = await firstValueFrom(
        this.collectionStore.pipe(select(CollectionReducer.getItemById(collectionId)), take(1)),
      );
      relevantInfo['collections'].push(this.localeService.getFromLocales(collection.name, 'nl').data);
    }

    relevantInfo['fields'] = [];
    for (const valueRoot of collectionItem.valueRoots) {
      const fieldRoot = await firstValueFrom(
        this.fieldRootStore.pipe(select(FieldRootReducer.getItemById(valueRoot.fieldRootId)), take(1)),
      );
      if (fieldRoot.immutable) {
        continue;
      }

      let fields = {
        name: this.localeService.getFromLocales(fieldRoot.name, 'nl').data,
        values: [],
      };

      for (const value of valueRoot.values) {
        if (value.hidden || !value.data) {
          continue;
        }
        const fieldValue = fieldRoot.fields.find(field => field.id === value.fieldId);
        let data: any = value.data;
        if (data?.length > 0 && data[0]?.data) {
          data = data.map(d => d.data);
        }
        let name = this.localeService.getFromLocales(fieldValue.name, 'nl').data;
        fields.values[name] = data;
      }

      relevantInfo['fields'].push(fields);
    }

    return relevantInfo;
  }

  async rateMessage(
    conversationId: string,
    messageId: string,
    rating: number,
    feedback: string = null,
  ): Promise<{
    success: boolean;
  }> {
    let postData = {
      conversationId: conversationId,
      messageId: messageId,
      rating: rating,
      feedback: feedback,
    };

    console.log('Rating message', postData);
    return firstValueFrom(this.http.post<{ success: boolean }>(this._apiUrl + '/rate', postData)).catch(() => {
      return { success: false };
    });
  }
}

export interface ChatGPTResponse {
  id: string;
  conversationId: string;
  tokens: {
    completion: number;
    prompt: number;
  };
  text: string;
}

export interface ChatDrawerConfig {
  apiUrl?: string;
}

export interface FieldData {
  name: string;
  fields: {
    name: string;
    editing?: boolean;
  }[];
  editing?: boolean;
}

export interface DemoFieldDataWrapper {
  fieldRoots: FieldData[];
  collectionItemName: string;
  templateName: string;
}
