import { Injectable } from '@angular/core';

// import {CrudService} from '@dash/randy/dashboard/services/crud.service';
// import {FileManagerModel} from '../classes/file-manager.model';
//
// @Injectable({
//     providedIn: 'root'
// })
// export class FileManagerService extends CrudService<FileManagerModel> {
//     constructor() {
//         super('fileManager');
//     }
// }
import { ApiRequestFileManagerItem, FileManagerItem } from '../classes/file-manager.model';
import { DBCrudService } from '@dash/randy/dashboard/services/db-crud.service';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthorizationService } from '@dash/randy/security/service/authorization.service';
import { ChecksumGenerateService } from '@dash/randy/shared/service/checksum-generate.service';
import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

export const httpOptionsForm = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class FileManagerItemService extends DBCrudService<FileManagerItem> {
  constructor(
    protected router: Router,
    protected http: HttpClient,
    protected authorizationService: AuthorizationService,
    protected checksumGenerate: ChecksumGenerateService,
  ) {
    super('files', router, http, authorizationService);
  }

  async saveItem(object: ApiRequestFileManagerItem): Promise<FileManagerItem> {
    let formData = new FormData();
    formData.append('file', object.file);
    formData.append('folderId', object.folderId);

    let md5Hash = await this.checksumGenerate.computeChecksumMd5(object.file);
    console.log(md5Hash, 'The md5 hash base64');

    // let exists = await this.checkIfItemExists(object.file.name, object.folderId, md5Hash);

    if (object.file.name.indexOf('.') === -1) {
      console.error('No extension found');
      throw new Error('No extension found');
    }
    const splittedName = object.file.name.split('.');
    const extension = '.' + splittedName.pop();
    const name = splittedName.join('.');
    const checkInData = await this.checkItemIn({
      name: name,
      extension: extension,
      folderId: object.folderId,
    });

    console.log(checkInData, 'Checkin data');

    const result = await firstValueFrom(this.http.put(checkInData.presignedUrl, object.file)).catch(err => {
      console.error(err);
      throw new Error('Couldnt upload file');
    });

    console.log(result, 'Uploaded with presigned url?');
    return this.getObject(checkInData.id);
  }

  private async checkItemIn(data: CheckinInput): Promise<CheckinOutput> {
    return firstValueFrom(this.http.post<CheckinOutput>(this.apiUrl, data, httpOptionsForm));
  }

  private async checkIfItemExists(fileName: string, folderId: string, checksum: string): Promise<boolean> {
    let data = { fileName, folderId, checksum };
    return new Promise(resolve => {
      this.http
        .post<string>(`${this.apiUrl}`, data, httpOptionsForm)
        .pipe(map(result => result === 'true'))
        .subscribe(exists => {
          resolve(exists);
        });
    });
  }
}

export interface CheckinInput {
  name: string;
  extension: string;
  folderId?: string;
}

export interface CheckinOutput {
  id: string;
  applicationId: string;
  presignedUrl: string;
}
